import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Photograph from '../components/Photograph';
import Loader from '../components/Loader';
import { getIsLoading, getHomePhotograph } from '../state/slices/home';

const Wrapper = styled.div`
    display: grid;
    min-height: 0;
`;

const Home = () => {
    const isLoading = useSelector(getIsLoading);
    const homePhotograph = useSelector(getHomePhotograph);

    return (
        <Wrapper>
            <Loader isVisible={isLoading} />
            {!isLoading && homePhotograph && (
                <Photograph photograph={homePhotograph} />
            )}
        </Wrapper>
    );
};

export default Home;
