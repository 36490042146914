import { createSlice } from '@reduxjs/toolkit';
import { SanityDocument } from '@sanity/client';
import { IPhotograph } from '../albums/slice';
import reducers from './reducers';

export interface IHome extends SanityDocument {
    photograph: IPhotograph;
}

export interface IState {
    photograph: IPhotograph;
    isLoading: boolean;
}

const initialState: IState = { photograph: null, isLoading: false };

const slice = createSlice({
    name: 'home',
    initialState,
    reducers: {},
    extraReducers: reducers,
});

export default slice.reducer;
