import React from 'react';
import styled from 'styled-components';
import { useTransition, animated, config } from 'react-spring';
import { Switch, Route, useLocation } from 'react-router-dom';
import routes from '../routes';

const Wrapper = styled(animated.div)`
    padding: 0 1rem;
    padding-bottom: 1rem;
    display: grid;
    grid-auto-flow: row;
    min-height: 0;
    position: relative;
    overflow: auto;
`;

const Content = () => {
    const location = useLocation();
    const transitions = useTransition(location.pathname, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { display: 'none' },
        config: config.gentle,
    });

    return (
        <React.Fragment>
            {transitions((style) => (
                <Wrapper style={style}>
                    <Switch>
                        {routes?.map((route) => (
                            <Route key={route.path} exact strict {...route} />
                        ))}
                    </Switch>
                </Wrapper>
            ))}
        </React.Fragment>
    );
};

export default Content;
