import React from 'react';
import styled from 'styled-components';
import HorizontalList from './HorizontalList';
import { Link, NavLink } from 'react-router-dom';

const Name = styled.h1`
    margin-bottom: 0;
    width: fit-content;
    white-space: nowrap;

    > * {
        font-weight: normal;
    }
`;

const Nav = styled.nav`
    margin: auto 0;
    white-space: nowrap;
`;

const Wrapper = styled.header`
    padding: 1rem;
`;

const Container = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    justify-content: space-between;
`;

const Header = () => {
    return (
        <Wrapper>
            <Container>
                <Nav>
                    <HorizontalList>
                        <NavLink
                            to="/photographs"
                            activeClassName="activeRoute"
                            exact
                        >
                            Photographs
                        </NavLink>
                    </HorizontalList>
                </Nav>
                <Name>
                    <Link to="/">Nabil J. Harb</Link>
                </Name>
                <Nav>
                    <HorizontalList>
                        <a
                            href="https://www.instagram.com/nabiljharb/"
                            target="_blank"
                        >
                            <i className="fab fa-instagram"></i>
                        </a>
                        <NavLink
                            to="/about"
                            activeClassName="activeRoute"
                            exact
                        >
                            About
                        </NavLink>
                    </HorizontalList>
                </Nav>
            </Container>
        </Wrapper>
    );
};

export default Header;
