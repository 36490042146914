import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import * as actions from './actions';
import { State } from './slice';

export default function (builder: ActionReducerMapBuilder<State>) {
    builder.addCase(actions.fetchAlbums.pending, (state) => {
        state.isLoading = true;
    });
    builder.addCase(actions.fetchAlbums.fulfilled, (state, action) => {
        state.photographs = action.payload[0].photographs;
        state.isLoading = false;
    });
    builder.addCase(actions.fetchAlbums.rejected, (state, action) => {
        state.isLoading = false;
    });
}