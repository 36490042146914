import React from 'react';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';

const Wrapper = styled(animated.div)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    overflow: hidden;
    z-index: 1;
`;

const Container = styled.div`
    margin: auto;
    text-align: center;
`;

const Loader = ({ isVisible }: { isVisible: boolean }) => {
    const transitions = useTransition(isVisible, {
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    });

    return (
        <React.Fragment>
            {transitions(
                (style, item) =>
                    item && (
                        <Wrapper style={style}>
                            <Container>
                                <div
                                    className="spinner-border text-primary"
                                    role="status"
                                ></div>
                            </Container>
                        </Wrapper>
                    )
            )}
        </React.Fragment>
    );
};

export default Loader;
