import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Content from './Content';
import { useDispatch } from '../state/store';
import { fetchAlbums } from '../state/slices/albums';
import { fetchAbout } from '../state/slices/about';
import { fetchHome } from '../state/slices/home';

const Wrapper = styled.div`
    width: 100vw !important;
    height: 100vh !important;
    color: #d4d4d4;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: min-content auto;
    overflow: auto;
`;

const App = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchAlbums());
        dispatch(fetchAbout());
        dispatch(fetchHome());
    }, []);

    return (
        <Wrapper>
            <Header />
            <Content />
        </Wrapper>
    );
};

export default App;
