import { createSlice } from '@reduxjs/toolkit';
import { SanityDocument } from '@sanity/client';
import reducers from './reducers';

export interface IPhotograph extends SanityDocument {
    url: string;
}

export interface IAlbum extends SanityDocument {
    photographs: IPhotograph[];
}

export interface IState {
    albums: IAlbum[];
    photographs: IPhotograph[];
    isLoading: boolean;
}

const initialState: IState = { albums: [], photographs: [], isLoading: false };

const slice = createSlice({
    name: 'albums',
    initialState,
    reducers: {},
    extraReducers: reducers,
});

export default slice.reducer;
