import React from 'react';
import styled from 'styled-components';
import type { Photograph } from '../state/slices/albums/slice';

interface Props {
    photograph: Photograph;
}

export const Image = styled.img`
    object-fit: contain;
    margin: auto;
    min-height: 0;
    height: 100%;
    width: 100%;
`;

export default ({ photograph }: Props) => {
    return <Image src={photograph.url} />;
};
