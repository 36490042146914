import React from 'react';
import styled from 'styled-components';
import { useTransition, animated, config } from 'react-spring';

interface Props {
    onRightNavigation: () => void;
    onLeftNavigation: () => void;
    hasRight: boolean;
    hasLeft: boolean;
}

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

const ArrowWrapper = styled.div`
    display: grid;
    align-content: center;
`;

const Button = styled(animated.button).attrs(() => ({
    className: 'btn btn-link',
}))<{ style: any }>`
    padding: 0;
    margin: 0;
    display: grid;
    color: var(--app-background-color) !important;
    text-decoration: none;
    z-index: 1;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    align-content: center;
    background-color: var(--bs-primary);
`;

const LeftButtonWrapper = styled(ArrowWrapper)`
    justify-content: left;
`;

const RightButtonWrapper = styled(ArrowWrapper)`
    justify-content: right;
`;

const NavigationOverlay = ({
    onRightNavigation,
    onLeftNavigation,
    hasRight,
    hasLeft,
}: Props) => {
    const leftButtonTransition = useTransition(hasLeft, {
        from: { opacity: 0, pointerEvents: 'none' },
        enter: { opacity: 1, pointerEvents: 'auto' },
        leave: { opacity: 0, pointerEvents: 'none' },
    });
    const rightButtonTransition = useTransition(hasRight, {
        from: { opacity: 0, pointerEvents: 'none' },
        enter: { opacity: 1, pointerEvents: 'auto' },
        leave: { opacity: 0, pointerEvents: 'none' },
    });

    return (
        <Wrapper>
            <LeftButtonWrapper>
                {leftButtonTransition(
                    (style, item) =>
                        item && (
                            <Button style={style} onClick={onLeftNavigation}>
                                <i className="fa fa-chevron-left"></i>
                            </Button>
                        )
                )}
            </LeftButtonWrapper>
            <RightButtonWrapper>
                {rightButtonTransition(
                    (style, item) =>
                        item && (
                            <Button style={style} onClick={onRightNavigation}>
                                <i className="fa fa-chevron-right"></i>
                            </Button>
                        )
                )}
            </RightButtonWrapper>
        </Wrapper>
    );
};

export default NavigationOverlay;
