import sanityClient from '@sanity/client';
import { IAbout } from '../state/slices/about/slice';
import { IAlbum } from '../state/slices/albums/slice';
import { IHome } from '../state/slices/home/slice';

const _sanityClient = sanityClient({
    projectId: 'mliy4ybx',
    dataset: process.env.NODE_ENV,
    apiVersion: '2022-07-04',
    useCdn: true,
});

export default {
    getAlbums: (): Promise<IAlbum[]> => {
        return _sanityClient.fetch(
            '*[_type=="album"]{..., photographs[]{..., "url": asset->url}}'
        );
    },
    getAbout: (): Promise<IAbout> => {
        return _sanityClient.fetch('*[_type=="about"][0]');
    },
    getHome: (): Promise<IHome> => {
        return _sanityClient.fetch(
            '*[_type=="home"]{..., photograph{..., "url": asset->url}}[0]'
        );
    },
};
