import React from 'react';
import styled from 'styled-components';
import uniqid from 'uniqid';

const UnstyledList = styled.ul`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-gap: 1rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

const HorizontalList = ({ children }: { children: React.ReactNode }) => {
    return (
        <UnstyledList>
            {React.Children.map(children, (child) => (
                <li key={uniqid()}>{child}</li>
            ))}
        </UnstyledList>
    );
};

export default HorizontalList;
