import React from 'react';
import styled from 'styled-components';
import { PortableText } from '@portabletext/react';
import { getBlocks } from '../state/slices/about';
import { useSelector } from '../state/store';

const Wrapper = styled.div`
	display:grid;
	grid-auto-flow: row;
	grid-auto-rows: min-content;
	justify-content: center;
	align-content: center;
`;

const About = () => {
	const blocks = useSelector(getBlocks);

	return (
		<Wrapper>
			<PortableText value={blocks}></PortableText>
		</Wrapper>
	);
};

export default About;