import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import * as actions from './actions';
import { State } from './slice';

export default function (builder: ActionReducerMapBuilder<State>) {
    builder.addCase(actions.fetchHome.pending, (state) => {
        state.isLoading = true;
    });
    builder.addCase(actions.fetchHome.fulfilled, (state, action) => {
        state.photograph = action.payload.photograph;
        state.isLoading = false;
    });
    builder.addCase(actions.fetchHome.rejected, (state) => {
        state.isLoading = false;
    });
}
