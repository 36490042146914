import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import * as actions from './actions';
import { State } from './slice';

export default function (builder: ActionReducerMapBuilder<State>) {
    builder.addCase(actions.fetchAbout.pending, (state) => {
        state.isLoading = true;
    });
    builder.addCase(actions.fetchAbout.fulfilled, (state, action) => {
        state.blocks = action.payload.about;
        state.isLoading = false;
    });
    builder.addCase(actions.fetchAbout.rejected, (state) => {
        state.isLoading = false;
    });
}