import { createSlice } from '@reduxjs/toolkit'
import { SanityDocument } from '@sanity/client';
import reducers from './reducers';

export interface IAbout extends SanityDocument {
  about: any[];
}

export interface IState {
  blocks: any;
  isLoading: boolean;
}

const initialState: IState = { blocks: null, isLoading: false }

const slice = createSlice({
  name: 'about',
  initialState,
  reducers: {},
  extraReducers: reducers
});

export default slice.reducer