import About from "./About";
import Home from "./Home";
import Photographs from "./Photographs";

export default [
    {
        path: '/', component: Home,
    },
    {
        path: '/photographs', component: Photographs
    },
    {
        path: '/about', component: About
    }
];