import { combineReducers } from '@reduxjs/toolkit';
import albums from './albums/slice';
import about from './about/slice';
import home from './home/slice';

export default combineReducers({
    albums,
    about,
    home,
});
