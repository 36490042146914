import React from 'react';
import styled from 'styled-components';
import uniqid from 'uniqid';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector } from '../state/store';
import {
    getAlbums,
    getPhotographs,
    getIsLoading,
} from '../state/slices/albums';
import Photograph, { Image } from '../components/Photograph';
import NavigationOverlay from '../components/NavigationOverlay';
import Loader from '../components/Loader';

const Wrapper = styled.div`
    position: relative;
`;

const ScrollbarsView = styled.div`
    position: absolute;
    inset: 0px;
    overflow: scroll;
    margin-right: -10px;
    margin-bottom: -10px;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    grid-template-rows: 100%;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    grid-gap: 1rem;
    will-change: transform;
`;

const PageWrapper = styled.div`
    display: grid;
    grid-auto-flow: column;
    scroll-snap-align: center;
    // grid-gap: 1rem;
    justify-content: center;
    grid-gap: 3rem;

    ${Image} {
        width: unset;
        max-width: 100%;
    }
`;

const Photographs = () => {
    const photographs = useSelector(getPhotographs);
    const albums = useSelector(getAlbums);
    const isLoading = useSelector(getIsLoading);
    const [hasRight, setHasRight] = React.useState(false);
    const [hasLeft, setHasLeft] = React.useState(false);
    const scrollbarsRef = React.useRef(null);

    const onRightNavigation = () => {
        scrollbarsRef.current.view.scrollBy(100, 0);
    };
    const onLeftNavigation = () => {
        scrollbarsRef.current.view.scrollBy(-100, 0);
    };
    const updateNavigationIndicators = () => {
        const { getScrollLeft, getClientWidth, getScrollWidth } =
            scrollbarsRef.current;
        setHasRight(getScrollLeft() + getClientWidth() < getScrollWidth());
        setHasLeft(getScrollLeft() > 0);
    };

    return (
        <Wrapper>
            <NavigationOverlay
                onLeftNavigation={onLeftNavigation}
                onRightNavigation={onRightNavigation}
                hasRight={hasRight}
                hasLeft={hasLeft}
            />
            <Scrollbars
                ref={scrollbarsRef}
                renderView={() => <ScrollbarsView />}
                onScrollStop={updateNavigationIndicators}
                universal={true}
            >
                <Loader isVisible={isLoading} />
                {!isLoading && (
                    <React.Fragment>
                        {photographs &&
                            photographs.map((photograph) => (
                                <PageWrapper
                                    key={uniqid()}
                                    onLoad={updateNavigationIndicators}
                                >
                                    <Photograph photograph={photograph} />
                                </PageWrapper>
                            ))}
                    </React.Fragment>
                )}
            </Scrollbars>
        </Wrapper>
    );
};

export default Photographs;
